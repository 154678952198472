import React, { useState, useEffect } from 'react'
import { useStoreContext } from '../../../../Utilities/globalState'
import { Spinner, Form, InputGroup, FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { usePlacesWidget } from 'react-google-autocomplete'
import { ref, uploadBytes, getDownloadURL, uploadBytesResumable } from '@firebase/storage'
import { storage } from '../../../../firebase-config'
import { doc, setDoc, GeoPoint, collection, query, where, getDocs, } from '@firebase/firestore'
import { parseDataForDB, reportNonBlockingError } from '../../../../Utilities/helpers'
import { db } from '../../../../firebase-config'
import './styles.scss'
import { GYMS_REF } from '../../../../Utilities/dbRefs'
import PremiumSubscription from '../Info/PremiumSubscription'

interface FeatureRowProps {
  feature: string;
  basic: string;
  pro: string;
  enterprise: string;
  tooltip: string;
}

const isURL = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};

const FeatureRow: React.FC<FeatureRowProps> = ({ feature, basic, pro, enterprise, tooltip }) => (
  <>
    <div className='row'>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${feature}`}>{tooltip}</Tooltip>}
      >
        <div className='feature'>
          <p>{feature}</p>
        </div>
      </OverlayTrigger>
      <div className='basic'>
        {isURL(basic) ? <a href={basic} target="_blank" rel="noopener noreferrer">See table</a> : basic}
      </div>
      <div className='pro'>{pro}</div>
      <div className='enterprise'>{enterprise}</div>
    </div>
    <div className='separator'></div>
  </>
);
export const Plans = () => {
  const features = [
    { feature: 'Global Payments', basic: 'Yes', pro: 'Yes', enterprise: 'Yes', tooltip: 'Support for payments across the globe including Apple Pay, Google Pay, Debit, and Credit.' },
    { feature: 'Analytics', basic: 'Yes', pro: 'Yes', enterprise: 'Yes', tooltip: 'Detailed analytics about your business.' },
    { feature: 'Visibility in search', basic: 'Yes', pro: 'Yes', enterprise: 'Yes', tooltip: 'Increased visibility in search results.' },
    { feature: 'Customizable page', basic: 'Yes', pro: 'Yes', enterprise: 'Yes', tooltip: 'Create a page tailored to your needs.' },
    { feature: 'Reviews', basic: 'Yes', pro: 'Yes', enterprise: 'Yes', tooltip: 'Collect and display customer reviews.' },
    { feature: 'Processing fee', basic: 'https://moregyms.mintlify.app/pages/pricing', pro: '0%', enterprise: '0%', tooltip: 'Fees associated with transactions. Stripe takes 2.9% + ¢30 per transaction.' },
    { feature: 'Text support', basic: 'No', pro: 'No', enterprise: 'Yes', tooltip: 'Support through text messages.' },
    { feature: 'Insights and recommendations', basic: 'No', pro: 'No', enterprise: 'Yes', tooltip: 'Personalized business insights.' },
    { feature: 'Dedicated account manager', basic: 'No', pro: 'No', enterprise: 'Yes', tooltip: 'A dedicated manager for your account.' },
    { feature: 'Control multiple pages', basic: 'No', pro: 'No', enterprise: 'Yes', tooltip: 'Manage multiple pages under one account.' }
  ];
  const handleTalkToSalesClick = () => {
    window.open('https://cal.com/moregyms/onboarding?date=2024-05-30&month=2024-05', '_blank');
  };
  return (
    <div className="plan-body">
      <div className='pricing-header'>
        <h1>We grow with your business</h1>
        <p>We believe that MoreGyms should be accessible to all businesses, regardless of their size.</p>
      </div>
      <div className='pricing-containers'>
        <div className='basic-plan'>
          <header className="basic-container">
            <div className='heading'>
              <h2>Basic</h2>
              <p>For gyms just starting</p>
            </div>
            <div className='price'>
              <h1>$0</h1>
              <p>/mo</p>
            </div>
            <div className='cta'>

              <button disabled={true} className='custom-button'>Default Plan</button>
            </div>
          </header>
        </div>
        <PremiumSubscription />
        <div className='franchise-plan'>
          <header className="franchise-container">
            <div className='heading'>
              <h2>Franchise</h2>
              <p>For operators with multiple locations.</p>
            </div>
            <div className='price'>
              <p>Contact us</p>
            </div>
            <div className='cta'>
            <button className='custom-button' onClick={handleTalkToSalesClick}>Talk to sales</button>
            </div>
          </header>
        </div>
      </div>
      <div className='pricing-header'>
        <h5>We have your back</h5>
        <p>See what each package comes with.</p>
      </div>
      <div className='divider'></div>

      <div className='pricing-difference'>
        {features.map((item, index) => (
          <FeatureRow
            key={index}
            feature={item.feature}
            basic={item.basic}
            pro={item.pro}
            enterprise={item.enterprise}
            tooltip={item.tooltip}
          />
        ))}
      </div>
    </div>
  )
}
